<template>
  <div class="card-item">
    <div class="img-container">
      <img
        v-if="data.productListImageUrl"
        :src="data.productListImageUrl | filterImg"
        alt="" />
      <div class="sex-key" v-if="data.dictSexKey === '男'">限男性</div>
      <div class="sex-key" v-else-if="data.dictSexKey === '女'">限女性</div>
      <div class="sex-key" v-else-if="data.dictSexKey === '男女通用'">
        男女通用
      </div>
    </div>
    <div class="card-info flex-column">
      <div class="card-title bold">{{ data.productName }}</div>
      <div class="card-types">
        <div>
          <span>{{ data.projectItemCount }}个检查项目</span>
          <span v-if="data.diseaseItemCount"
            >筛查{{ data.diseaseItemCount }}个疾病</span
          >
        </div>
        <span v-for="(item, index) in data.diseaseItems" :key="index">{{
          item
        }}</span>
      </div>
      <div class="card-price">
        <span v-if="!data.isActive" class="sale-price">
          <span class="bold" style="font-size: 12px">￥</span>
          <span class="bold" style="font-size: 17px;">{{ data.salePrice }}</span>
        </span>
        <span v-else class="original-price"
          >原价 ￥{{ data.originalPrice }}</span
        >
        <span class="appointmentCount"> 已约{{ data.appointmentCount }} </span>
      </div>
      <div class="activity-container" v-if="data.isActive">
        <div class="activity-price">
          <span class="bold" style="display: flex; align-items: baseline">
            <span class="bold" style="font-size: 12px">￥</span>
            <span class="bold" style="font-size: 17px;">{{ data.salePrice }}</span>
          </span>
          <span class="activity-text">活动价</span>
        </div>
        <div class="activity-appointment">预约</div>
        <img
          class="icon-flash"
          src="https://img.glztj.com/images/202412/618981800288325.png"
          alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "card-item",
  data() {
    return {
      dictSellingPointKeys: [],
    };
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.dictSellingPointKeys = this.$props.data.dictSellingPointKeys.splice(
      0,
      2
    );
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.card-item {
  width: 100%;
  padding: 6px 10px 13px 10px;
  display: flex;
  margin-bottom: 10px;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  z-index: 1;
  .img-container {
    position: relative;
    width: 80px;
    height: 95px;
    border-radius: 8px;
    margin-right: 10px;
  }

  .img img {
    width: 70px;
    height: 70px;
    border-radius: 12px;
  }

  .sex-key {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 7px;
    background: linear-gradient(220deg, #ee7c3c 3%, #ff3d00 100%);
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    height: 15px;
    color: #fff;
  }
  .card-title {
    color: #333;
    font-size: 15px;
    line-height: 18px;
  }
  .card-info {
    margin-top: 16px;
    flex: 1;
  }
  .card-types {
    span {
      display: inline-block;
      padding: 3px 4px;
      margin: 4px 5px 0 0;
      color: #878d99;
      background: #f8f8f8;
      border-radius: 4px;
      font-size: 12px;
    }
    div {
      font-size: 12px;
      span:nth-child(-n + 2) {
        color: #0091ff;
        background: rgba(60, 196, 255, 0.1);
        font-size: 12px;
      }
    }
  }
  .card-price {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 5px;
    .sale-price {
      font-size: 17px;
      color: #ff3d00;
    }
    .appointmentCount {
      margin-left: 10px;
      font-size: 12px;
      color: #878d99;
    }
  }
}

.original-price {
  font-size: 12px;
  color: #d9d9d9;
  text-decoration: line-through;
}

.activity-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  width: 100%;
  height: 28px;
  background: rgba(255, 61, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
}

.activity-price {
  display: flex;
  align-items: center;
  padding: 0 8px;
  flex: 1;
  font-size: 17px;
  color: #ff3d00;
}

.activity-text {
  margin-left: 4px;
  font-size: 12px;
  color: #ff3d00;
}

.activity-appointment {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 67px;
  height: 100%;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  background: #ff3d00;
}

.icon-flash {
  position: absolute;
  top: 0;
  right: 52px;
  width: 21px;
  height: 100%;
}
</style>
